import React, { Component } from "react";
import EmptyState from "../EmptyState";


class AdminPage extends Component {
  render() {
    return <EmptyState title="Admin" 
    description="What are you waiting for to log in!"

    />;
  }
}

export default AdminPage;
