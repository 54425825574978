import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  DialogContent,
  Divider,
  Fab,
  Fade,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  AccessTime as AccessTimeIcon,
  Cake as CakeIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  FitnessCenter as FitnessCenterIcon,
  Height as HeightIcon,
  Person as PersonIcon,
  PersonOutline as PersonOutlineIcon,
  Photo as PhotoIcon,
  Sports as SportsIcon,
  Warning as WarningIcon,
  Wc as WcIcon,
  SportsHandball as SportsHandballIcon,
  Map as MapIcon,
  EditLocation as EditLocationIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
} from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import validate from "validate.js";
import constraints from "../../constraints";
import authentication from "../../services/authentication";
import { areas, Cities } from "./CityNames";
const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  avatar: {
    marginRight: "auto",
    marginLeft: "auto",

    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
});

const initialState = {
  profileCompletion: 0,
  securityRating: 0,
  showingField: "",
  performingAction: false,
  errors: null,
  area: "",
  city: "",
  histo1: "",
  team: "",
  histo2: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class CityTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };

  hideFields = (callback) => {
    this.setState(
      {
        showingField: "",
        area: "",
        city: "",
        histo1: "",
        histo2: "",
        team: "",
        errors: null,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  changeArea = () => {
    const { area } = this.state;

    const errors = validate(
      {
        area: area,
      },
      {
        area: constraints.area,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (area === userData.area) {
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeArea(area)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeCity = () => {
    const { city } = this.state;

    const errors = validate(
      {
        city: city,
      },
      {
        city: constraints.city,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (city === userData.city) {
          console.log("Same city");
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeCity(city)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeHisto1 = () => {
    const { histo1 } = this.state;

    const errors = validate(
      {
        histo1: histo1,
      },
      {
        histo1: constraints.histo1,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (histo1 === userData.histo1) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeHisto1(histo1)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeTeam = () => {
    const { team } = this.state;

    const errors = validate(
      {
        team: team,
      },
      {
        team: constraints.team,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (team === userData.team) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeTeam(team)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeHisto2 = () => {
    const { histo2 } = this.state;

    const errors = validate(
      {
        histo2: histo2,
      },
      {
        histo2: constraints.histo2,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (histo2 === userData.histo2) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeHisto2(histo2)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeField = (fieldId) => {
    switch (fieldId) {
      case "area":
        this.changeArea();
        return;

      case "city":
        this.changeCity();
        return;

      case "histo1":
        this.changeHisto1();
        return;

      case "team":
        this.changeTeam();
        return;

      case "histo2":
        this.changeHisto2();
        return;

      default:
        return;
    }
  };

  handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === "Escape") {
      this.hideFields();
    } else if (key === "Enter") {
      console.log("Pressed Enter");
      this.changeField(fieldId);
    }
  };

  handleAreaChange = (event) => {
    if (!event) {
      return;
    }

    const area = event.target.value;

    this.setState({
      area: area,
    });
  };

  handleCityChange = (event) => {
    if (!event) {
      return;
    }

    const city = event.target.value;

    this.setState({
      city: city,
    });
  };

  handleHisto1Change = (event) => {
    if (!event) {
      return;
    }

    const histo1 = event.target.value;

    this.setState({
      histo1: histo1,
    });
  };

  handleTeamChange = (event) => {
    if (!event) {
      return;
    }

    const team = event.target.value;

    this.setState({
      team: team,
    });
  };
  handleHisto2Change = (event) => {
    if (!event) {
      return;
    }

    const histo2 = event.target.value;

    this.setState({
      histo2: histo2,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { user, userData } = this.props;

    // Events
    const { onDeleteAccountClick } = this.props;

    const {
      profileCompletion,
      securityRating,
      showingField,
      performingAction,
      loadingAvatar,
      avatar,
      avatarUrl,
      errors,
      area,
      city,
      histo1,
      team,
      histo2,
    } = this.state;

    const hasArea = userData && userData.area;
    const hasCity = userData && userData.city;
    const hasHisto1 = userData && userData.histo1;
    const hasTeam = userData && userData.team;
    const hasHisto2 = userData && userData.histo2;

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List disablePadding>
          {/* Area */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
            </Hidden>

            {!hasArea && (
              <ListItemIcon>
                <Tooltip title="No first Area">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "area" && (
              <>
                <FormControl
                  variant="outlined"
                  style={{
                    minWidth: 120,
                  }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Area
                  </InputLabel>
                  <Select
                    value={area}
                    onChange={this.handleAreaChange}
                    onKeyDownCapture={(event) =>
                      this.handleKeyDown(event, "area")
                    }
                    label="Area"
                  >
                    {areas.map((area) => (
                      <MenuItem key={area} value={area}>
                        {area}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ListItemSecondaryAction>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => (area ? this.changeArea() : null)}
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </>
            )}

            {showingField !== "area" && (
              <>
                <ListItemText
                  primary="Area"
                  secondary={hasArea ? userData.area : "You don’t have a Area"}
                />

                <ListItemSecondaryAction>
                  {hasArea && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("area")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasArea && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("area")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          {/* City */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <EditLocationIcon />
              </ListItemIcon>
            </Hidden>

            {!hasCity && (
              <ListItemIcon>
                <Tooltip title="No City">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "city" && (
              <>
                <FormControl
                  variant="outlined"
                  style={{
                    minWidth: 120,
                  }}
                >
                  <InputLabel>City</InputLabel>
                  <Select
                    value={city}
                    onChange={this.handleCityChange}
                    onKeyDownCapture={(event) =>
                      this.handleKeyDown(event, "city")
                    }
                    label="City"
                  >
                    {userData.area &&
                      Cities[userData.area].map((x) => (
                        <MenuItem key={x} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <ListItemSecondaryAction>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => (city ? this.changeCity() : null)}
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </>
            )}

            {showingField !== "city" && (
              <>
                <ListItemText
                  primary="City"
                  secondary={hasCity ? userData.city : "You don’t have city"}
                />

                <ListItemSecondaryAction>
                  {hasCity && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("city")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasCity && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("city")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          {/* History */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
            </Hidden>

            {!hasHisto1 && (
              <ListItemIcon>
                <Tooltip title="No Histo">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "histo1" && (
              <TextField
                autoComplete="histo1"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.histo1)}
                fullWidth
                helperText={
                  errors && errors.histo1
                    ? errors.histo1[0]
                    : "Press Enter to change your histo1"
                }
                label="Histo1"
                placeholder={hasHisto1 && userData.histo1}
                required
                type="text"
                value={histo1}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "histo1")}
                onChange={this.handleHisto1Change}
              />
            )}

            {showingField !== "histo1" && (
              <>
                <ListItemText
                  primary="Histo1"
                  secondary={
                    hasHisto1 ? userData.histo1 : "You don’t have histo1"
                  }
                />

                <ListItemSecondaryAction>
                  {hasHisto1 && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("histo1")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasHisto1 && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("histo1")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          {/* Team or school name */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
            </Hidden>

            {!hasTeam && (
              <ListItemIcon>
                <Tooltip title="No Team">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "team" && (
              <TextField
                autoComplete="team"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.team)}
                fullWidth
                helperText={
                  errors && errors.team
                    ? errors.team[0]
                    : "Press Enter to change your team"
                }
                label="Team"
                placeholder={hasTeam && userData.bodyInfo.team}
                required
                type="text"
                value={team}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "team")}
                onChange={this.handleTeamChange}
              />
            )}

            {showingField !== "team" && (
              <>
                <ListItemText
                  primary="Team"
                  secondary={hasTeam ? userData.team : "You don’t have Team"}
                />

                <ListItemSecondaryAction>
                  {hasTeam && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("team")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasTeam && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("team")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          {/* More History */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
            </Hidden>

            {!hasHisto2 && (
              <ListItemIcon>
                <Tooltip title="No Histo2">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "histo2" && (
              <TextField
                autoComplete="histo2"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.histo2)}
                fullWidth
                helperText={
                  errors && errors.histo2
                    ? errors.histo2[0]
                    : "Press Enter to change your Histo2"
                }
                label="Histo2"
                placeholder={hasHisto2 && userData.histo2}
                required
                type="text"
                value={histo2}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "histo2")}
                onChange={this.handleHisto2Change}
              />
            )}

            {showingField !== "histo2" && (
              <>
                <ListItemText
                  primary="Histo2"
                  secondary={
                    hasHisto2 ? userData.histo2 : "You don’t have histo2"
                  }
                />

                <ListItemSecondaryAction>
                  {hasHisto2 && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("histo2")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasHisto2 && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("histo2")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        </List>
      </DialogContent>
    );
  }

  componentDidMount() {
    const { user, userData } = this.props;

    this.setState({
      profileCompletion: authentication.getProfileCompletion({
        ...user,
        ...userData,
      }),
      securityRating: authentication.getSecurityRating(user, userData),
    });
    // this.setState({
    //   Sports: userData.Sports,
    // });
  }

  componentWillUnmount() {
    const { avatarUrl } = this.state;

    if (avatarUrl) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatarUrl: "",
      });
    }
  }
}

CityTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,

  // Functions
  openSnackbar: PropTypes.func.isRequired,

  // Events
  onDeleteAccountClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(CityTab);
