const names = [
  "野球",
  "サッカー",
  "ラグビー",
  "陸上",
  "バスケットボール",
  "バレーボール",
  "アメリカン",
  "テニス",
  "ハンドボール",
];



const baseBallNames = [
  "ピッチャー（右）",
  "ピッチャー（左）",
  "キャッチャー",
  "ファースト",
  "セカンド",
  "ショート",
  "サード",
  "レフト",
  "センター",
  "ライト",
  "その他",
];

const soccerNames = [
  "GK",
  "DF（センターバック）",
  "DF（右サイドバック）",
  "DF（左サイドバック）",
  "MF（ボランチ）",
  "MF（右サイドハーフ）",
  "MF（左サイドハーフ）",
  "MF（トップ下）",
  "FW（右ウィング）",
  "FW（左ウィング）",
  "FW（センターフォワード）",
];

const basketBallNames = [
  "ポイントガード",
  "シューティングガード",
  "スモールフォワード",
  "パワーフォワード",
  "センター",
];
const volyNames = [
  "セッター",
  "センタープレイヤー（ミドルブロッカー）",
  "アタッカー（ウィングスパイカー）",
  "リベロ",
  "オポジット",
];
const tenisNames = ["シングルス", "ダブルス"];

const handBallNames = ["GK", "LB", "CB", "RB", "LW", "P", "RW"];
const rugnyNames = [
  "プロップ",
  "フッカー",
  "ロック",
  "フランカー",
  "ナンバーエイト",
  "スクラムハーフ",
  "スタンドオフ",
  "ウィング",
  "センター",
  "フルバック",
];
const americanNames = [
  "QB",
  "OL",
  "RB",
  "TE",
  "WR",
  "DL",
  "LB",
  "DB",
  "K",
  "P",
  "S",
];

const landNames = [
    "100m",
    "400m",
    "800m",
    "1500m",
    "5000m",
    "10000m",
    "100mハードル",
    "110mハードル",
    "400mハードル",
    "3000m障害",
    "4×100mリレー",
    "4×400mリレー",
    "マラソン",
    "20km競歩",
    "50km競歩",
    "走高跳",
    "棒高跳",
    "走幅跳",
    "三段跳",
    "砲丸投",
    "円盤投",
    "ハンマー投",
    "200m",
    "やり投げ",
    "十種競技",
    "七種競技",
]

const Possitions = {
    "野球":baseBallNames,
    "サッカー": soccerNames,
    "ラグビー": rugnyNames,
    "陸上": landNames,
    "バスケットボール": basketBallNames,
    "バレーボール": volyNames,
    "アメリカン": americanNames,
    "テニス": tenisNames,
    "ハンドボール": handBallNames,
};

const PossitionsEmpty = {
    "野球":[],
    "サッカー": [],
    "ラグビー": [],
    "陸上": [],
    "バスケットボール": [],
    "バレーボール": [],
    "アメリカン": [],
    "テニス": [],
    "ハンドボール": [],
};

export { names, Possitions, PossitionsEmpty };

