import { Box, Fab, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Home as HomeIcon, Refresh as RefreshIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { firestore } from "../../firebase";
import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";
import EmptyState from "../EmptyState";
import Loader from "../Loader";
import UserCard from "../UserCard";
import UserProfile from "../UserProfile/UserProfile";
import Album from "../UserProfile/Album";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginLeft: 0,
    width: "100%",
  },
  profile: {
    minWidth: 500,
    maxWidth: 800,
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    maxWidth: "sm"
  },
}));

function UserPage() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const classes = useStyles();

  useEffect(() => {
    return firestore
      .collection("users")
      .doc(userId)
      .onSnapshot(
        (snapshot) => {
          setLoading(false);
          setUser(snapshot.data());
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
  }, [userId]);

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user"
        description="Something went wrong when trying to retrieve the requested user"
        button={
          <Fab
            variant="extended"
            color="primary"
            onClick={() => window.location.reload()}
          >
            <Box clone mr={1}>
              <RefreshIcon />
            </Box>
            Retry
          </Fab>
        }
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <EmptyState
        image={<NoDataIllustration />}
        title="User doesn’t exist"
        description="The requested user doesn’t exist"
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }

  return (
    <Album user={user}/>
    // <Grid container justify="center" spacing={5}>
    //   <Grid item xs={6}>
    //     <UserCard user={user} />
    //     <UserProfile user={user} />
    //     <Album/>
    //   </Grid>
    // </Grid>
  );
}

export default UserPage;
