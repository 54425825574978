const areas = [
  "北海道地方",
  "東北地方",
  "関東地方",
  "中部地方",
  "近畿地方",
  "中国地方",
  "四国地方",
  "九州地方",
];



const a1 = [
  "北海道地方",
];

const a2 = [
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
];

const a3 = [
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
];
const a4 = [
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
];

const a5 = [
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
]
const a6 = [
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
]
const a7 = [
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
]

const a8 = [
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
]


const Cities = {
  "北海道地方": a1,
  "東北地方": a2,
  "関東地方": a3,
  "中部地方": a4,
  "近畿地方": a5,
  "中国地方": a6,
  "四国地方": a7,
  "九州地方": a8,
};


export { areas, Cities };

