import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import UserProfile from "./UserProfile";
import UserProfile2 from "./UserProfile2";

const useStyles = makeStyles({
  card: {
    display: "flex",
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { user, prof} = props;

  return (
    <Grid item xs={12} sm={6} md={6}>
      {prof==1 ? <UserProfile user={user} /> : <UserProfile2 user={user} />}
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};
