import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  DialogContent,
  Divider,
  Fab,
  Fade,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  AccessTime as AccessTimeIcon,
  Cake as CakeIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  FitnessCenter as FitnessCenterIcon,
  Height as HeightIcon,
  Person as PersonIcon,
  PersonOutline as PersonOutlineIcon,
  Photo as PhotoIcon,
  Sports as SportsIcon,
  Warning as WarningIcon,
  Wc as WcIcon,
  SportsHandball as SportsHandballIcon,
} from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import validate from "validate.js";
import constraints from "../../constraints";
import authentication from "../../services/authentication";
import { names, Possitions, PossitionsEmpty } from "./SportsNames";

const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  avatar: {
    marginRight: "auto",
    marginLeft: "auto",

    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
});

const initialState = {
  profileCompletion: 0,
  securityRating: 0,
  showingField: "",
  avatar: null,
  avatarUrl: "",
  firstName: "",
  lastName: "",
  username: "",
  emailAddress: "",
  performingAction: false,
  loadingAvatar: false,
  sentVerificationEmail: false,
  errors: null,
  sex: "",
  body: {
    birth: "",
    sex: "",
    weight: "",
    height: "",
  },
  Sports: [],
  MyPossitions: PossitionsEmpty,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RenderHello = (props) => {
  const greeting = "Hello Function Component!";

  return <h1>{props.name}</h1>;
};

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  getNameInitialsOrIcon = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const { classes, userData } = this.props;

    if (!userData) {
      return <PersonIcon className={classes.personIcon} />;
    }

    const nameInitials = authentication.getNameInitials({
      ...user,
      ...userData,
    });

    if (nameInitials) {
      return (
        <Typography className={classes.nameInitials} variant="h2">
          {nameInitials}
        </Typography>
      );
    }

    return <PersonIcon className={classes.personIcon} />;
  };

  uploadAvatar = () => {
    const { avatar } = this.state;

    if (!avatar) {
      return;
    }

    this.setState(
      {
        performingAction: true,
        loadingAvatar: true,
      },
      () => {
        authentication
          .changeAvatar(avatar)
          .then((value) => {
            const { user, userData } = this.props;

            this.setState({
              profileCompletion: authentication.getProfileCompletion({
                ...user,
                ...userData,
              }),
            });
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
              loadingAvatar: false,
              avatar: null,
              avatarUrl: "",
            });
          });
      }
    );
  };

  removeAvatar = () => {
    const { user } = this.props;

    const { avatar, avatarUrl } = this.state;

    if (!user.photoURL && !avatar && !avatarUrl) {
      return;
    }

    if (
      (!user.photoURL && avatar && avatarUrl) ||
      (user.photoURL && avatar && avatarUrl)
    ) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatar: null,
        avatarUrl: "",
      });
    } else if (user.photoURL && !avatar && !avatarUrl) {
      this.setState(
        {
          performingAction: true,
          loadingAvatar: true,
        },
        () => {
          authentication
            .removeAvatar()
            .then((value) => {
              const { user, userData } = this.props;

              this.setState({
                profileCompletion: authentication.getProfileCompletion({
                  ...user,
                  ...userData,
                }),
              });
            })
            .catch((reason) => {
              const code = reason.code;
              const message = reason.message;

              switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
              }
            })
            .finally(() => {
              this.setState({
                performingAction: false,
                loadingAvatar: false,
              });
            });
        }
      );
    }
  };

  showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };

  hideFields = (callback) => {
    this.setState(
      {
        // initialState
        showingField: "",
        firstName: "",
        lastName: "",
        username: "",
        emailAddress: "",
        errors: null,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  changeHeight = () => {
    const { height } = this.state.body;
    const errors = validate(
      {
        height: height,
      },
      {
        height: constraints.firstName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (height === userData.bodyInfo ? userData.bodyInfo.height : null) {
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeHeight(height)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };
  changeWeight = () => {
    const { weight } = this.state.body;
    const errors = validate(
      {
        weight: weight,
      },
      {
        weight: constraints.firstName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (weight === userData.bodyInfo ? userData.bodyInfo.weight : null) {
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeWeight(weight)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };
  changeBirth = () => {
    const { birth } = this.state.body;
    const errors = validate(
      {
        birth: birth,
      },
      {
        birth: constraints.firstName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (birth === (userData.bodyInfo ? userData.bodyInfo.birth : null)) {
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeBirth(birth)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeSex = () => {
    const { sex } = this.state.body;

    const errors = validate(
      {
        sex: sex,
      },
      {
        sex: constraints.firstName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (sex === (userData.bodyInfo ? userData.bodyInfo.sex : null)) {
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeSex(sex)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeSports = () => {
    const { Sports } = this.state;
    console.log(`changeSports: This is sports: ${Sports}`);
    const errors = validate(
      {
        Sports: Sports,
      },
      {
        Sports: constraints.Sports,
      }
    );

    if (errors) {
      console.log(`changeSports: There was error: ${errors}`);
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;
        console.log(`UserDataSport is: ${userData.Sports}`);
        console.log(`Sport is: ${Sports}`);
        if (Sports == userData.Sports) {
          console.log("Same Sports")
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeSports(Sports)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changePossitions = () => {
    const { MyPossitions } = this.state;
    const errors = validate(
      {
        MyPossitions: MyPossitions,
      },
      {
        MyPossitions: constraints.Possitions,
      }
    );

    if (errors) {
      console.log(`changePossitions: There was error: ${errors}`);
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;
        console.log(`UserDataPossition is: ${userData.Possitions}`);
        console.log(`Possition is: ${MyPossitions}`);
        if (MyPossitions === userData.Possitions) {
          console.log("Where same Poss")
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changePossitions(MyPossitions)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeFirstName = () => {
    const { firstName } = this.state;

    const errors = validate(
      {
        firstName: firstName,
      },
      {
        firstName: constraints.firstName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (firstName === userData.firstName) {
          console.log("Same input")
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeFirstName(firstName)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeLastName = () => {
    const { lastName } = this.state;

    const errors = validate(
      {
        lastName: lastName,
      },
      {
        lastName: constraints.lastName,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (lastName === userData.lastName) {
          this.hideFields();
          this.setState({
            performingAction: false,
          });
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeLastName(lastName)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeUsername = () => {
    const { username } = this.state;

    const errors = validate(
      {
        username: username,
      },
      {
        username: constraints.username,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { userData } = this.props;

        if (username === userData.username) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeUsername(username)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeEmailAddress = () => {
    const { emailAddress } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const { user } = this.props;

        if (emailAddress === user.email) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            authentication
              .changeEmailAddress(emailAddress)
              .then(() => {
                const { user, userData } = this.props;

                this.setState(
                  {
                    profileCompletion: authentication.getProfileCompletion({
                      ...user,
                      ...userData,
                    }),
                  },
                  () => {
                    this.hideFields();
                  }
                );
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                  default:
                    this.props.openSnackbar(message);
                    return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  verifyEmailAddress = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .verifyEmailAddress()
          .then(() => {
            this.setState(
              {
                sentVerificationEmail: true,
              },
              () => {
                this.props.openSnackbar("Sent verification e-mail");
              }
            );
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                this.props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  changeField = (fieldId) => {
    switch (fieldId) {
      case "first-name":
        this.changeFirstName();
        return;

      case "last-name":
        this.changeLastName();
        return;

      case "username":
        this.changeUsername();
        return;

      case "email-address":
        this.changeEmailAddress();
        return;

      case "sex":
        this.changeSex();
        return;
      case "birth":
        this.changeBirth();
        return;
      case "height":
        this.changeHeight();
        return;
      case "weight":
        this.changeWeight();
        return;

      case "Sports":
        console.log("Sports");
        this.changeSports();
        return;

      case "Possitions":
        console.log("Possitions");
        this.changePossitions();
        return;
      default:
        return;
    }
  };

  handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === "Escape") {
      this.hideFields();
    } else if (key === "Enter") {
      console.log("Pressed Enter");
      this.changeField(fieldId);
    }
  };

  handleAvatarChange = (event) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];

    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    this.setState({
      avatar: avatar,
      avatarUrl: URL.createObjectURL(avatar),
    });
  };

  handleBirthChange = (event) => {
    if (!event) {
      return;
    }

    const birth = event.target.value;

    this.setState({
      body: { birth: birth },
    });
  };

  handleHeightChange = (event) => {
    if (!event) {
      return;
    }

    const height = event.target.value;

    this.setState({
      body: { height: height },
    });
  };

  handleWeightChange = (event) => {
    if (!event) {
      return;
    }

    const weight = event.target.value;

    this.setState({
      body: { weight: weight },
    });
  };
  handleSexChange = (event) => {
    if (!event) {
      return;
    }

    const sex = event.target.value;

    this.setState({
      body: { sex: sex },
    });
  };

  handleFirstNameChange = (event) => {
    if (!event) {
      return;
    }

    const firstName = event.target.value;

    this.setState({
      firstName: firstName,
    });
  };

  handleLastNameChange = (event) => {
    if (!event) {
      return;
    }

    const lastName = event.target.value;

    this.setState({
      lastName: lastName,
    });
  };

  handleSportChange = (event) => {
    if (!event) {
      return;
    }

    const Sports = event.target.value;
    console.log(Sports);
    this.setState({
      Sports: Sports,
    });
  };

  handlePossitionChange = (sport, event) => {
    if (!event) {
      return;
    }

    const possitions = event.target.value;
    console.log(`This is the sport you want: ${possitions}`);
    console.log(`This is the sport you want: ${sport}`);
    console.log(this.state.MyPossitions);
    this.setState({
      MyPossitions: {
        ...this.state.MyPossitions,
        [sport]: possitions,
      },
    });
  };

  handleUsernameChange = (event) => {
    if (!event) {
      return;
    }

    const username = event.target.value;

    this.setState({
      username: username,
    });
  };

  handleEmailAddressChange = (event) => {
    if (!event) {
      return;
    }

    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { user, userData } = this.props;

    // Events
    const { onDeleteAccountClick } = this.props;

    const {
      profileCompletion,
      securityRating,
      showingField,
      performingAction,
      loadingAvatar,
      avatar,
      avatarUrl,
      firstName,
      lastName,
      username,
      emailAddress,
      sentVerificationEmail,
      errors,
      sex,
      body,
      Sports,
      MyPossitions,
    } = this.state;

    const hasFirstName = userData && userData.firstName;
    const hasLastName = userData && userData.lastName;
    const hasUsername = userData && userData.username;
    const hasUserbody = userData && userData.bodyInfo;
    const hasUsersex = !hasUserbody ? false : userData && userData.bodyInfo.sex;
    const hasUserbirth = !hasUserbody
      ? false
      : userData && userData.bodyInfo.birth;
    const hasUserweight = !hasUserbody
      ? false
      : userData && userData.bodyInfo.weight;
    const hasUserheight = !hasUserbody
      ? false
      : userData && userData.bodyInfo.height;
    const hasUserSports = !hasUserbody ? false : userData && userData.Sports;
    const hasUserPossitions = userData && userData.Possitions;
    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box mb={2}>
          <Hidden xsDown>
            <Grid alignItems="center" container>
              <Grid item xs>
                <Box textAlign="center">
                  <Box mb={1.5}>
                    {avatar && avatarUrl && (
                      <Badge
                        classes={{ badge: classes.badge }}
                        badgeContent={
                          <Tooltip title="Remove">
                            <Fab
                              classes={{ sizeSmall: classes.small }}
                              color="secondary"
                              disabled={performingAction}
                              size="small"
                              onClick={this.removeAvatar}
                            >
                              <CloseIcon fontSize="small" />
                            </Fab>
                          </Tooltip>
                        }
                      >
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <Fade
                                style={{ transitionDelay: "1s" }}
                                in={loadingAvatar}
                                unmountOnExit
                              >
                                <CircularProgress size={120} thickness={1.8} />
                              </Fade>
                            }
                          >
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={avatarUrl}
                            />
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={avatarUrl}
                          />
                        )}
                      </Badge>
                    )}

                    {!avatar && !avatarUrl && (
                      <>
                        {user.photoURL && (
                          <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={
                              <Tooltip title="Remove">
                                <Fab
                                  classes={{ sizeSmall: classes.small }}
                                  color="secondary"
                                  disabled={performingAction}
                                  size="small"
                                  onClick={this.removeAvatar}
                                >
                                  <CloseIcon fontSize="small" />
                                </Fab>
                              </Tooltip>
                            }
                          >
                            {loadingAvatar && (
                              <Badge
                                classes={{ badge: classes.loadingBadge }}
                                badgeContent={
                                  <Fade
                                    style={{ transitionDelay: "1s" }}
                                    in={loadingAvatar}
                                    unmountOnExit
                                  >
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  </Fade>
                                }
                              >
                                <Avatar
                                  className={classes.avatar}
                                  alt="Avatar"
                                  src={user.photoURL}
                                />
                              </Badge>
                            )}

                            {!loadingAvatar && (
                              <Avatar
                                className={classes.avatar}
                                alt="Avatar"
                                src={user.photoURL}
                              />
                            )}
                          </Badge>
                        )}

                        {!user.photoURL && (
                          <>
                            {loadingAvatar && (
                              <Badge
                                classes={{ badge: classes.loadingBadge }}
                                badgeContent={
                                  <Fade
                                    style={{ transitionDelay: "1s" }}
                                    in={loadingAvatar}
                                    unmountOnExit
                                  >
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  </Fade>
                                }
                              >
                                <Avatar className={classes.avatar} alt="Avatar">
                                  {this.getNameInitialsOrIcon()}
                                </Avatar>
                              </Badge>
                            )}

                            {!loadingAvatar && (
                              <Avatar className={classes.avatar} alt="Avatar">
                                {this.getNameInitialsOrIcon()}
                              </Avatar>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  {avatar && avatarUrl && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      startIcon={<CloudUploadIcon />}
                      variant="contained"
                      onClick={this.uploadAvatar}
                    >
                      Upload
                    </Button>
                  )}

                  {!avatar && !avatarUrl && (
                    <>
                      <input
                        id="avatar-input"
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={this.handleAvatarChange}
                      />

                      <label htmlFor="avatar-input">
                        <Button
                          color="primary"
                          component="span"
                          disabled={performingAction}
                          startIcon={<PhotoIcon />}
                          variant="contained"
                        >
                          Choose...
                        </Button>
                      </label>
                    </>
                  )}
                </Box>
              </Grid>

              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Profile completion</Typography>

                  {profileCompletion === 0 && (
                    <Typography color="error" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}

                  {profileCompletion === 100 && (
                    <Typography color="primary" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}

                  {profileCompletion !== 0 && profileCompletion !== 100 && (
                    <Typography color="secondary" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Security rating</Typography>

                  {securityRating === 0 && (
                    <Typography color="error" variant="h5">
                      {securityRating}%
                    </Typography>
                  )}

                  {securityRating === 100 && (
                    <Typography color="primary" variant="h5">
                      {securityRating}%
                    </Typography>
                  )}

                  {securityRating !== 0 && securityRating !== 100 && (
                    <Typography color="secondary" variant="h5">
                      {securityRating}%
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Box textAlign="center" mb={3}>
              <Box mb={1.5}>
                {avatar && avatarUrl && (
                  <Badge
                    classes={{ badge: classes.badge }}
                    badgeContent={
                      <Tooltip title="Remove">
                        <Fab
                          classes={{ sizeSmall: classes.small }}
                          color="secondary"
                          disabled={performingAction}
                          size="small"
                          onClick={this.removeAvatar}
                        >
                          <CloseIcon fontSize="small" />
                        </Fab>
                      </Tooltip>
                    }
                  >
                    {loadingAvatar && (
                      <Badge
                        classes={{ badge: classes.loadingBadge }}
                        badgeContent={
                          <Fade
                            style={{ transitionDelay: "1s" }}
                            in={loadingAvatar}
                            unmountOnExit
                          >
                            <CircularProgress size={120} thickness={1.8} />
                          </Fade>
                        }
                      >
                        <Avatar
                          className={classes.avatar}
                          alt="Avatar"
                          src={avatarUrl}
                        />
                      </Badge>
                    )}

                    {!loadingAvatar && (
                      <Avatar
                        className={classes.avatar}
                        alt="Avatar"
                        src={avatarUrl}
                      />
                    )}
                  </Badge>
                )}

                {!avatar && !avatarUrl && (
                  <>
                    {user.photoURL && (
                      <Badge
                        classes={{ badge: classes.badge }}
                        badgeContent={
                          <Tooltip title="Remove">
                            <Fab
                              classes={{ sizeSmall: classes.small }}
                              color="secondary"
                              disabled={performingAction}
                              size="small"
                              onClick={this.removeAvatar}
                            >
                              <CloseIcon fontSize="small" />
                            </Fab>
                          </Tooltip>
                        }
                      >
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <CircularProgress size={120} thickness={1.8} />
                            }
                          >
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={user.photoURL}
                            />
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={user.photoURL}
                          />
                        )}
                      </Badge>
                    )}

                    {!user.photoURL && (
                      <>
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <Fade
                                style={{ transitionDelay: "1s" }}
                                in={loadingAvatar}
                                unmountOnExit
                              >
                                <CircularProgress size={120} thickness={1.8} />
                              </Fade>
                            }
                          >
                            <Avatar className={classes.avatar} alt="Avatar">
                              {this.getNameInitialsOrIcon()}
                            </Avatar>
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar className={classes.avatar} alt="Avatar">
                            {this.getNameInitialsOrIcon()}
                          </Avatar>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>

              {avatar && avatarUrl && (
                <Button
                  color="primary"
                  disabled={performingAction}
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  onClick={this.uploadAvatar}
                >
                  Upload
                </Button>
              )}

              {!avatar && !avatarUrl && (
                <>
                  <input
                    id="avatar-input"
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={this.handleAvatarChange}
                  />

                  <label htmlFor="avatar-input">
                    <Button
                      color="primary"
                      component="span"
                      disabled={performingAction}
                      startIcon={<PhotoIcon />}
                      variant="contained"
                    >
                      Choose...
                    </Button>
                  </label>
                </>
              )}
            </Box>

            <Grid container>
              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Profile completion</Typography>

                  {profileCompletion === 0 && (
                    <Typography color="error" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}

                  {profileCompletion === 100 && (
                    <Typography color="primary" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}

                  {profileCompletion !== 0 && profileCompletion !== 100 && (
                    <Typography color="secondary" variant="h5">
                      {profileCompletion}%
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs>
                <Box textAlign="center">
                  <Typography variant="body1">Security rating</Typography>

                  {securityRating === 0 && (
                    <Typography color="error" variant="h5">
                      {securityRating}%
                    </Typography>
                  )}

                  {securityRating === 100 && (
                    <Typography color="primary" variant="h5">
                      {securityRating}%
                    </Typography>
                  )}

                  {securityRating !== 0 && securityRating !== 100 && (
                    <Typography color="secondary" variant="h5">
                      {securityRating}%
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Hidden>
        </Box>

        <List disablePadding>
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>

            {!hasFirstName && (
              <ListItemIcon>
                <Tooltip title="No first name">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "first-name" && (
              <TextField
                autoComplete="given-name"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.firstName)}
                fullWidth
                helperText={
                  errors && errors.firstName
                    ? errors.firstName[0]
                    : "Press Enter to change your first name"
                }
                label="First name"
                placeholder={hasFirstName && userData.firstName}
                required
                type="text"
                value={firstName}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "first-name")}
                onChange={this.handleFirstNameChange}
              />
            )}

            {showingField !== "first-name" && (
              <>
                <ListItemText
                  primary="First name"
                  secondary={
                    hasFirstName
                      ? userData.firstName
                      : "You don’t have a first name"
                  }
                />

                <ListItemSecondaryAction>
                  {hasFirstName && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("first-name")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasFirstName && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("first-name")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>

            {!hasLastName && (
              <ListItemIcon>
                <Tooltip title="No last name">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "last-name" && (
              <TextField
                autoComplete="family-name"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.lastName)}
                fullWidth
                helperText={
                  errors && errors.lastName
                    ? errors.lastName[0]
                    : "Press Enter to change your last name"
                }
                label="Last name"
                placeholder={hasLastName && userData.lastName}
                required
                type="text"
                value={lastName}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "last-name")}
                onChange={this.handleLastNameChange}
              />
            )}

            {showingField !== "last-name" && (
              <>
                <ListItemText
                  primary="Last name"
                  secondary={
                    hasLastName
                      ? userData.lastName
                      : "You don’t have a last name"
                  }
                />

                <ListItemSecondaryAction>
                  {hasLastName && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("last-name")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasLastName && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("last-name")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <WcIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUsersex && (
              <ListItemIcon>
                <Tooltip title="No sex">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "sex" && (
              <>
                <FormControl>
                  <InputLabel htmlFor="sex-native-simple">Sex</InputLabel>
                  <Select
                    type="text"
                    required
                    native
                    value={body.sex}
                    onChange={this.handleSexChange}
                    onKeyDown={(event) => this.handleKeyDown(event, "sex")}
                    inputProps={{
                      name: "sex",
                      id: "sex-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={"Male"}>Male</option>
                    <option value={"Female"}>Female</option>
                    {/* <option value={30}></option> */}
                  </Select>
                </FormControl>
                <ListItemSecondaryAction>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => (body.sex ? this.changeField("sex") : null)}
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </>
            )}

            {showingField !== "sex" && (
              <>
                <ListItemText
                  primary="Sex"
                  secondary={
                    hasUsersex ? userData.bodyInfo.sex : "You don’t have sex"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUsersex && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("sex")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUsersex && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.changeField("sex")}
                    >
                      Save
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <CakeIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUserbirth && (
              <ListItemIcon>
                <Tooltip title="No Birthday">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "birth" && (
              <>
                <TextField
                  autoComplete="birth"
                  autoFocus
                  disabled={performingAction}
                  error={!!(errors && errors.birth)}
                  // fullWidth
                  helperText={
                    errors && errors.birth
                      ? errors.birth[0]
                      : "Press Enter to change your birthday"
                  }
                  label="Birthday"
                  placeholder={hasUserbirth && userData.bodyInfo.birth}
                  required
                  type="date"
                  value={body.birth}
                  variant="filled"
                  InputLabelProps={{ required: false }}
                  // onBlur={this.hideFields}
                  onKeyDown={(event) => this.handleKeyDown(event, "birth")}
                  onChange={this.handleBirthChange}
                />
                <ListItemSecondaryAction>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => (body.birth ? this.changeBirth() : null)}
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </>
            )}

            {showingField !== "birth" && (
              <>
                <ListItemText
                  primary="Birthday"
                  secondary={
                    hasUserbirth
                      ? userData.bodyInfo.birth
                      : "You don’t have birthday"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUserbirth && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("birth")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUserbirth && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("birth")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          {/* Height            */}

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <HeightIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUserheight && (
              <ListItemIcon>
                <Tooltip title="No Height">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "height" && (
              <TextField
                autoComplete="height"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.height)}
                fullWidth
                helperText={
                  errors && errors.height
                    ? errors.height[0]
                    : "Press Enter to change your height"
                }
                label="Height"
                placeholder={hasUserheight && userData.bodyInfo.height}
                required
                type="text"
                value={body.height}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "height")}
                onChange={this.handleHeightChange}
              />
            )}

            {showingField !== "height" && (
              <>
                <ListItemText
                  primary="Height"
                  secondary={
                    hasUserheight
                      ? userData.bodyInfo.height
                      : "You don’t have height"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUserheight && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("height")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUserheight && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("height")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          {/* Weight            */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <FitnessCenterIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUserheight && (
              <ListItemIcon>
                <Tooltip title="No Weight">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "weight" && (
              <TextField
                autoComplete="weight"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.weight)}
                fullWidth
                helperText={
                  errors && errors.weight
                    ? errors.height[0]
                    : "Press Enter to change your weight"
                }
                label="Weight"
                placeholder={hasUserweight && userData.bodyInfo.weight}
                required
                type="text"
                value={body.weight}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "weight")}
                onChange={this.handleWeightChange}
              />
            )}

            {showingField !== "weight" && (
              <>
                <ListItemText
                  primary="Weight"
                  secondary={
                    hasUserweight
                      ? userData.bodyInfo.weight
                      : "You don’t have weight"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUserweight && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("weight")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUserweight && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("weight")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          {/* Sports            */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <SportsIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUserSports && (
              <ListItemIcon>
                <Tooltip title="No Sports">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "Sports" && (
              <>
                <FormControl>
                  <InputLabel id="demo-mutiple-chip-label">Sports</InputLabel>
                  <Select
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      minWidth: 120,
                    }}
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={Sports}
                    onChange={this.handleSportChange}
                    onKeyDownCapture={(event) =>
                      this.handleKeyDown(event, "Sports")
                    }
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors && errors.city
                      ? errors.city[0]
                      : "Press Enter to change your Sport"}
                  </FormHelperText>
                </FormControl>
                <ListItemSecondaryAction>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => (Sports ? this.changeSports() : null)}
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </>
            )}

            {showingField !== "Sports" && (
              <>
                <ListItemText
                  primary="Sports"
                  secondary={
                    hasUserSports
                      ? userData.Sports.join(",")
                      : "You don’t have Sports"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUserSports && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("Sports")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUserSports && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("Sports")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          {/* List of Possitions */}

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <SportsHandballIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUserPossitions && (
              <ListItemIcon>
                <Tooltip title="No Possitions">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "Possitions" && (
              <>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={5}>
                    {userData.Sports.map((value) => (
                      <Grid key={value} item>
                        <FormControl>
                          <InputLabel>{value}</InputLabel>
                          <Select
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              minWidth: 120,
                            }}
                            multiple
                            value={MyPossitions[value]}
                            onChange={(e) =>
                              this.handlePossitionChange(value, e)
                            }
                            onKeyDownCapture={(event) =>
                              this.handleKeyDown(event, "Possitions")
                            }
                            input={<Input />}
                            renderValue={(selected) => {
                              console.log(`THis is selected: ${selected}`);
                              return (
                                <div>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                  ))}
                                </div>
                              );
                            }}
                            MenuProps={MenuProps}
                          >
                            {Possitions[value].map((val) => (
                              <MenuItem key={val} value={val}>
                                <Checkbox
                                  checked={MyPossitions[value].includes(val)}
                                />
                                <ListItemText primary={val} />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {errors && errors.MyPossitions
                              ? errors.MyPossitions[0]
                              : "Press Enter to change your possitions"}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <ListItemSecondaryAction>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      MyPossitions ? this.changePossitions() : null
                    }
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </>
            )}

            {showingField !== "Possitions" && (
              <>
                <ListItemText
                  primary="Possitions"
                  secondary={
                    hasUserPossitions
                      ? userData.Sports.map((x) =>
                          userData.Possitions[x].map((y) => y)
                        ).join("; ")
                      : "You don’t have Possitions"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUserPossitions && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("Possitions")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUserPossitions && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("Possitions")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          {/* End of List of pos */}
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonOutlineIcon />
              </ListItemIcon>
            </Hidden>

            {!hasUsername && (
              <ListItemIcon>
                <Tooltip title="No username">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "username" && (
              <TextField
                autoComplete="username"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.username)}
                fullWidth
                helperText={
                  errors && errors.username
                    ? errors.username[0]
                    : "Press Enter to change your username"
                }
                label="Username"
                placeholder={hasUsername && userData.username}
                required
                type="text"
                value={username}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) => this.handleKeyDown(event, "username")}
                onChange={this.handleUsernameChange}
              />
            )}

            {showingField !== "username" && (
              <>
                <ListItemText
                  primary="Username"
                  secondary={
                    hasUsername
                      ? userData.username
                      : "You don’t have a username"
                  }
                />

                <ListItemSecondaryAction>
                  {hasUsername && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("username")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!hasUsername && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("username")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
            </Hidden>

            {user.email && (
              <ListItemIcon>
                <>
                  {user.emailVerified && (
                    <Tooltip title="Verified">
                      <CheckIcon color="primary" />
                    </Tooltip>
                  )}

                  {!user.emailVerified && (
                    <Tooltip title="Not verified">
                      <WarningIcon color="error" />
                    </Tooltip>
                  )}
                </>
              </ListItemIcon>
            )}

            {!user.email && (
              <ListItemIcon>
                <Tooltip title="No e-mail address">
                  <WarningIcon color="error" />
                </Tooltip>
              </ListItemIcon>
            )}

            {showingField === "email-address" && (
              <TextField
                autoComplete="email"
                autoFocus
                disabled={performingAction}
                error={!!(errors && errors.emailAddress)}
                fullWidth
                helperText={
                  errors && errors.emailAddress
                    ? errors.emailAddress[0]
                    : "Press Enter to change your e-mail address"
                }
                label="E-mail address"
                placeholder={user.email}
                required
                type="email"
                value={emailAddress}
                variant="filled"
                InputLabelProps={{ required: false }}
                onBlur={this.hideFields}
                onKeyDown={(event) =>
                  this.handleKeyDown(event, "email-address")
                }
                onChange={this.handleEmailAddressChange}
              />
            )}

            {showingField !== "email-address" && (
              <>
                <ListItemText
                  primary="E-mail address"
                  secondary={
                    user.email ? user.email : "You don’t have an e-mail address"
                  }
                />

                {user.email && !user.emailVerified && (
                  <Box clone mr={7}>
                    <ListItemSecondaryAction>
                      <Tooltip title="Verify">
                        <div>
                          <IconButton
                            color="secondary"
                            disabled={performingAction || sentVerificationEmail}
                            onClick={this.verifyEmailAddress}
                          >
                            <CheckIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </Box>
                )}

                <ListItemSecondaryAction>
                  {user.email && (
                    <Tooltip title="Change">
                      <div>
                        <IconButton
                          disabled={performingAction}
                          onClick={() => this.showField("email-address")}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}

                  {!user.email && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      variant="contained"
                      onClick={() => this.showField("email-address")}
                    >
                      Add
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>

          {/* <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
            </Hidden>

            <Hidden xsDown>
              <ListItemText
                primary="Signed in"
                secondary={moment(user.metadata.lastSignInTime).format("LLLL")}
              />
            </Hidden>

            <Hidden smUp>
              <ListItemText
                primary="Signed in"
                secondary={moment(user.metadata.lastSignInTime).format("llll")}
              />
            </Hidden>
          </ListItem>

          <Box mt={1} mb={1}>
            <Divider light />
          </Box>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary="Delete account"
              secondary="Accounts can’t be recovered"
            />

            <ListItemSecondaryAction>
              <Button
                color="secondary"
                disabled={performingAction}
                variant="contained"
                onClick={onDeleteAccountClick}
              >
                Delete
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
         */}
        </List>
      </DialogContent>
    );
  }

  componentDidMount() {
    const { user, userData } = this.props;

    this.setState({
      profileCompletion: authentication.getProfileCompletion({
        ...user,
        ...userData,
      }),
      securityRating: authentication.getSecurityRating(user, userData),
    });
    // this.setState({
    //   Sports: userData.Sports,
    // });
    if (userData && userData.Sports) {
      this.setState({
        Sports: userData.Sports,
      });
    }

    if (userData && userData.Possitions) {
      this.setState({
        MyPossitions: userData.Possitions,
      });
    }

    // userData && userData.Possitions ? {} : this.setState({
    //   MyPossitions: userData.Possitions,
    // })

    // const hasUserPossitions = userData && userData.Possitions
    // if(hasUserPossitions){
    //   this.setState({
    //     MyPossitions: userData.Possitions,
    //   })
    // }
  }

  componentWillUnmount() {
    const { avatarUrl } = this.state;

    if (avatarUrl) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatarUrl: "",
      });
    }
  }
}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,

  // Functions
  openSnackbar: PropTypes.func.isRequired,

  // Events
  onDeleteAccountClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
